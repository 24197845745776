* {
  font-family: "Proxima Nova regular";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

div p {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: unset
}



section.section {
  padding-bottom: 90px;

  @media screen and (max-width: 768px) {
    padding-bottom: 70px;
  }

}

section.section.first {
  padding-top: 90px;

  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }

}

section.section.last {
  padding-bottom: 70px;

  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
  }

}

// slick
.slick-dots li {
  margin: 0 3px;
}
// END slick

// input styles
input.input, textarea, select {
  position: relative;
  box-sizing: border-box;
  //border-radius: 5px;
  display: block;
  padding: 20px 40px;
  font-size: 16px;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  transition: all 200ms;
  border: 0px solid transparent;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;

  &.error {
    padding: 15px 40px 25px;
    border: 1px solid rgb(254, 218, 206);
  }

  &.success{
    background: #f8fff7;
    border: 1px solid rgba(0, 217, 0, 0.55);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 10000;
    width: 30px;
    height: 30px;
    background: #000;
    top:0;
    right: 0;
  }

  &::placeholder {
    font-size: 15px;
    color: #a7a7a7;
  }

  @media screen and (max-width: 490px) {
    padding: 24px 40px !important;
    box-sizing: border-box;
    //border-radius: 5px;
    background-color: rgb(255, 255, 255);
    &.error {
      padding: 19px 40px 29px !important;

    }
  }

  & + .error {
    box-sizing: border-box;
    font-size: 12px;
    background: rgb(254, 218, 206);
    padding: 2px 20px;
    position: absolute;
    bottom: 0px;
    width: 100% !important;
    text-align: center;
    color: #000;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0 !important;
  }

  textarea.input {
    min-height: 7em !important;
  }
}
// END input styles
