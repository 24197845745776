.miniBlock{
    padding:10px;
    text-align: left;
}
.miniBlock img{
width: 14px;
height: 14px;
margin-right: 10px;
}
.imgTextBlock{
    display: flex;
    flex-direction: row;
}
.textS {
    font-family: 'Roboto Thin';
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    padding-bottom:20px;
}
.textB{
    font-family: 'Roboto Regular';
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
}
.textM{
    font-family: 'Roboto Thin';
    font-size: 16px; 
    line-height: 22px;
    font-weight: 600;
    padding-bottom: 25px;

}