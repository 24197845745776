.section {
  display: block;
  max-width: 1440px;
  margin: 0 auto;
  font-family: "Proxima Nova regular";
  

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 50px;
  }

  .textBlock {
    font-size: 18px;
    font-weight: 900;
  }
  .textBlock p {
    font-family: "Proxima Nova Thin";
    text-align: left;
    font-size: 17px;
    font-weight: 300;
    line-height: 37px;
  }
  .textBlock p:first-child {
    font-family: "Proxima Nova Extra Bold";
    font-size: 32px;
    font-weight: 800;
    line-height: 47px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .premium {
    color: #67a668;
    font-weight: 600;
  }
  .wrapperCard {
    display: grid;
    position: relative;
    grid-template-columns: 45% 1fr;
     max-height: 396px;
    align-items: center;
    border-radius: 10px;
    // margin: 0 auto;
    background: linear-gradient(130deg, #262626 0%, #262626 57%, #4a4a4a 100%);
    padding: 0 5%;
    margin: 25px 0;
    color: #ffffff;
  }
  .wrapperCard:before {
    content: "";
    position: absolute;
    height: 99%;
    width: 100%;
    max-width: 175px;
    left: 2%;
    background-image: url("../img/greenCircleGreyCard.png");
  }
  .wrapperCard:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 156px;
    max-height: 140px;
    border-radius: 10px;
    z-index: 2;
    right: 0;
    bottom: 0;
    background-image: url("../img/rightCircleGreyCard.png");
  }
  .wrapperWide {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
     max-height: 400px;
    align-items: center;
    border-radius: 10px;
    // margin: 0 auto;
    background: linear-gradient(130deg, #262626 0%, #262626 57%, #4a4a4a 100%);
    padding: 0 5%;
    margin: 25px 0;
    color: #ffffff;
  }
  .wrapperWide:before {
    content: "";
    position: absolute;
    height: 97%;
    width: 100%;
    max-width: 175px;
    left: 2%;
    background-image: url("../img/greenCircleGreyCard.png");
  }
  .wrapperWide:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 156px;
    max-height: 140px;
    border-radius: 10px;
    z-index: 2;
    right: 0;
    bottom: 0;
    background-image: url("../img/rightCircleGreyCard.png");
  }

  .greeenCircleHalf {
    position: relative;
  }
  .greeenCircleHalf:before {
    display: none;
    content: "";
    max-width: 175px;
    transform: scale(0.35);
    max-height: 400px;
    left: 66%;
    bottom: 36%;
    background-image: url("../img/greenCircleGreyCard2.png");
  }
  .wrapperCardSpecial {
    display: grid;
    position: relative;
    grid-template-columns: auto 1fr;
    width: 100%;
    max-height: 400px;
    align-items: center;
    border-radius: 10px;
    // margin: 0 auto;
    background: linear-gradient(130deg, #262626 0%, #262626 57%, #4a4a4a 100%);
    padding: 0 5%;
    margin: 25px 0;
    color: #ffffff;
  }
  .wrapperCardSpecial:before {
    content: "";
    position: absolute;
    height: 97%;
    width: 100%;
    max-width: 175px;
    left: 2%;
    background-image: url("../img/greenCircleGreyCard.png");
  }
  .wrapperCardSpecial:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 156px;
    max-height: 140px;
    border-radius: 10px;
    z-index: 2;
    right: 0;
    bottom: 0;
    background-image: url("../img/rightCircleGreyCard.png");
  }

  .wrapperCardSpecial {
    display: grid;
    position: relative;
    grid-template-columns: auto 1fr;
    width: 100%;
    max-height: 400px;
    align-items: center;
    border-radius: 10px;
    // margin: 0 auto;
    background: linear-gradient(130deg, #262626 0%, #262626 57%, #4a4a4a 100%);
    padding: 0 5%;
    margin: 25px 0;
    color: #ffffff;
  }
  .extraBlock{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
}

  .extraBlock p{
    font-size: 12px;
    line-height: 20px;
    font-family: "Proxima Nova Thin";
    padding-bottom: 26px;
    padding-top: 15px;
    font-weight: 600;
  }

  .card {
    width: 100%;
    max-width: 480px;
    max-height: 400px;
    justify-self: center;
    justify-content: center;
    column-gap: 15px;
    border-radius: 10px;
  }
  .cardTextBlock {
    display: grid;
    grid-template-rows: 20% auto;
    height: 100%;
    padding: 49px 0;
    align-items: center;
    align-content: center;
    z-index: 3;
  }
  .cardTitle:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 19px;
    max-height: 19px;
    left: 0;
    top: 15%;
    background-image: url("../img/greenBallGreyCard.png");
  }

  .descrBlock {
    display: grid;
    grid-template-columns: 41% 1fr;
  }
  .descrSecondBlock {
    display: flex;
    max-width: 554px;
  }
  .descrSecondBlockSpecial {
    display: flex;
    max-width: 554px;
    flex-direction: column;
  }
  .descrSecondBlockSpecial p {
    font-family: "Proxima Nova Thin";
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 10px;
  }
  .descrSecondBlockSpecial span {
    font-size: "Proxima Nova Thin";
    font-weight: 900;
    font-size: 16px;
    line-height: 26px;
  }
  .listBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 5px;
   }
  .cardTitle p {
    font-family: "Proxima Nova Extra Bold";
    font-weight: 900;
    padding-left: 10px;
    font-size: 26px;
  }
  .cardTitle ::before {
    content: "";
    position: absolute;
    max-width: 175px;
    transform: scale(0.35);
    max-height: 400px;
    left: 66%;
    bottom: 36%;
    background-image: url("../img/greenBallGreyCard.png");
  }
  .cardTitle {
    padding-left: 20px;
    position: relative;
  }
  .descrBlock span {
    font-size: 14px;
    line-height: 20px;
    font-family: "Proxima Nova Thin";
    // padding-bottom: 26px;
    // padding-top: 15px;
    font-weight: 600;
  }
  .extratext{
    font-size: 12px ;
  }

  .dotlist {
    max-width: 260px;
    margin-left: -5px;
    padding-top: 15px;

  }
  .descrBlock li {
    font-size: 12px;
    line-height: 25px;
    font-weight: 300;
    padding-left: 7px;
    font-family: "Proxima Nova Thin";
    list-style-type: disc;
    color:white;
  }
  .descrBlock li::marker {
    font-size: 1.3rem;
  }
  .extraInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .extraInfo p {
    font-size: 14px;
    font-family: "Proxima Nova Thin";
    padding-bottom: 26px;
    font-weight: 600;
  }
  .imgblock {
    position: relative;
    height: 100%;
    text-align: center;
    width: 100%;
  }
  .imgblock img{
    margin-right: 60px;

  }
  .imgBlockS {
    position: relative;
    height: 100%;
    text-align: center;
    width: 100%;
    .pimage{
      max-width: 333px;
    }
  }
 
  .imgBlockWide{
    z-index:10
  }
  .imgblockTightPhoto {
    position: relative;
    height: 100%;
    text-align: center;
    width: 100%;
    margin-left: -15%;

  }

  .image {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 380px;
    max-width: 269px;
    // left: 20px;
    top: 0;
  }
  .imageslim {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 380px;
    max-width: 269px;
    left: 25%;
    top: 0;
  }
  .imageslimextra {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 400px;
    // max-width: 269px;
    width: auto;
    left: 40%;
    top: 0;
  }
 

  @media screen and (min-width: 481px) and (max-width: 750px) {
    .cardTitle:before {
      left: 6px;
      bottom: 65px;
      transform: scale(0.7);
    }
    .wrapperCard:before {
      display: none;
    }
    .textBlock p {
      font-size: 13px;
      line-height: 20px;
      padding-left: 3px;
    }
    .descrBlock {
      gap: 20px;
    }
    .cardTitle p{
      position: absolute;
    bottom: 30%;
    }
  }

  @media screen and (max-width: 750px) {
    .cardTextBlock {
      max-width: 200px;
      grid-template-rows: 5% auto;
    }
    .cardTextBlockWide{
      max-width: 350px !important;
    }
    .wrapperCard:before {
      display: none;
    }
    .textBlock p {
      font-size: 13px;
      line-height: 20px;
    }
    .extraBlock{
      grid-template-rows: 25% 25%;
      grid-template-columns: 1fr;
    }
    .textBlock p:first-child {
      font-size: 28px;
      line-height: 20px;
    }
    .cardTitle:before {
      left: 8px;
      bottom: 52px;
      transform: scale(0.7);
    }
    .cardTitle p{
      position: absolute;
    bottom: 30%;
    }
    .wrappercard{
      width: auto;
    }
    .wrapper{
      width: auto;

    }

    .wrapper {
      padding: 0 13px;
      width: auto;
    }
    .cardTitle p {
      padding-left: 10px;
    }
   .specialTitle p{
    margin-left: -10px !important;
   }
    .wrapperCard {
      grid-template-columns: 1fr;
      width: auto;
      max-height: 480px;
      padding-bottom: 20px;
    }
    .wrapperWide {
      grid-template-rows: 150px 1fr;
      grid-template-columns: 1fr;
    }
    .imgBlockWide {
      grid-row: 2;
      padding-top: 20px;
      margin: 0 auto;
    }
    .wrapperCardSpecial {
      grid-template-columns: 1fr;
      width: 100%;
      max-height: 500px;
      padding-bottom: 20px;
    }
    .wrapperCardSpecial {
      grid-template-rows: 150px max-content;
      width: 100%;
      max-height: 500px;
      padding-bottom: 20px;
    }
    .imgblock {
      position: absolute;
      left: 42%;
      top: 11%;
    }
    .imgblock img {
      max-width: 100px;
      width: 100%;
      position: relative;
      z-index: 3;
    }
    .imgBlockWide {
      left: 27%;
      top: 5%;
    }
    .imgBlockWide {
      img {
        max-width: 305px;
        width: 100%;
        position: relative;
      }
    }
    .imgblockTightPhoto {
      position: absolute;
      left: 45%;
    top: 3%;
    }
    .imgblockTightPhoto img {
      max-width: 70px;
      width: 100%;
      position: relative;
      z-index: 5;
    }

    .descrBlock {
      grid-template-rows: 33% 1fr;
      grid-template-columns: 1fr;
      padding: 0 10px;
      gap: 25px;

    }
    .descrBlockSpecial {
      max-width: 190px;
    }
    .descrSecondBlockSpecial p {
      font-size: 13px;
      line-height: 20px;
      // max-width: 190px;
    }
    .descrSecondBlockSpecial span {
      font-size: 13px;
      line-height: 20px;
    }
    .descrBlock span {
      padding-bottom: 15px;
      font-size: 13px;
      line-height: 17px;
    }
    .extraInfo p {
      font-size: 9px;
      padding-right: 5px;
      font-weight: 400;
    }
    .dotlist li {
      font-size: 13px;
      line-height: 17px;
      padding-left: 0;
    }
    .cardTitle {
      padding-left: 0;
      position: relative;
    }
    .cardTitle p {
      font-size: 16px;
    }
    .extratext{
      font-size: 12px !important;
      padding-right: 8px !important;

    }

    .textBlock p:first-child {
      line-height: 30px;
      text-align: center;
    }
    //  .cardTextBlock{
    //   grid-template-rows: 10% auto;

    //  }
    .dotlist {
      max-width: 150px;
    }
    // .rightBlock {
    //   padding-top: 10px;
    // }
    .wrapperCard:before {
      content: "";
      max-width: 175px;
      transform: scale(0.35);
      max-height: 400px;
      left: 66%;
      bottom: 36%;
      background-image: url("../img/greenCircleGreyCard2.png");
    }
    .greeenCircleHalf:before {
      display: block;
      content: "";
      max-width: 175px;
      transform: scale(0.35);
      max-height: 400px;
      left: 66%;
      bottom: 36%;
      background-image: url("../img/greenCircleGreyCard2.png");
    }
    .wrapperWide:before {
      display: none;
    }
    .wrapperCard:after {
      max-width: 175px;
      transform: scale(0.6);
      max-width: 90px;
      max-height: 75px;
      right: -31px;
      bottom: -28px;
      max-width: 156px;
      max-height: 140px;
    }
    .wrapperCardSpecial:before {
      // content: "";
      // max-width: 175px;
      // transform: scale(0.35);
      // max-height: 400px;
      // left: 66%;
      // bottom: 36%;
      // background-image: url("../img/greenCircleGreyCard2.png");
      display: none;
    }
    .wrapperCardSpecial:after {
      max-width: 175px;
      transform: scale(0.6);
      max-width: 90px;
      max-height: 75px;
      right: -31px;
      z-index: 2;
      bottom: -28px;
      max-width: 156px;
      max-height: 140px;
    }

    .imgBlockS img {
      max-width: 150px;
      position: absolute;
    }

    .imgBlockS {
      right: -22px;
      top: 37px;
      .pimage{
        max-width: 150px;
      }
    }
  }
}
