.section {
  display: block;
  max-width: 1440px;
  margin: 0 auto;
  font-family: "Proxima Nova regular";
}

.wrapper {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  transition-duration: 500ms;
  background-color: black;
  margin-top: -20%;
}
.wrapper::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 600px;
  max-height: 800px;
  left: 2%;
  background-image: url("../img/waves.jpg");
}

.wrapper::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 550px;
  max-height: 700px;
  right: 0;
  top: 0;
  z-index: 2;
  background-image: url("../img/snackbox.png");
}
.button {
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-family: "Roboto Thin";
  background: linear-gradient(104.68deg, #709a71 -8.55%, #178e35 87.61%);
  max-height: 80px;
  max-width: 240px;
  padding: 12px 0;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  transition: all 200ms;
  margin-top: 25px;
  color: white;
}

// .bonus {
//   background-color: #be041d;
//   border-radius: 10px;
//   height: 80px;
//   border: none;
//   padding: 0 40px;
//   transform: skew(-10deg);
//   font-family: "Proxima Nova Black";
//   font-size: 27px;
//   color: white;
//   cursor: pointer;
// }

.container {
  box-sizing: border-box;
  padding-bottom: 100px;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  height: 700px;
  margin: 0 auto;
  overflow: hidden;
  transition-duration: 500ms;

  @media (max-width: 600px) {
    padding-bottom: 30px;
  }
  @media (max-width: 320px) {
    padding-bottom: 0;
  }
}
.buttonContainer {
  .container {
    display: flex;
    max-width: 286px;
    max-height: 80px;
    align-items: center;

    margin: 0 auto;
  }
}
.leftBlock {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: absolute;
  top: 41%;
  right: 48%;
  max-width: 450px;
  z-index: 6;
}
.leftBlock p:first-child {
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  font-family: "Proxima Nova Black";
}
.leftBlock p {
  font-family: "Proxima Nova Thin";
  font-weight: 600;
  padding-top: 15px;
  font-size: 17px;
  line-height: 33px;
}
.rightBlock {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 40%;
}
.rightBlock img {
  width: 100%;
  height: 100%;
  max-width: 630px;
  z-index: 5;
}
@media screen and (max-width: 750px) {
  .wrapper::before {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .leftBlock {
    font-weight: 800;
    font-size: 34px;
    line-height: 38px;
    margin-top: 25%;
    position: relative;
    top: 0;
    right: 0;
    padding: 0 20px;
  }
  .rightBlock {
    bottom: -27px;
    left: -20%;
    right: -15%;
  }
  .leftBlock p:first-child {
    font-size: 34px;
    line-height: 40px;
  }
  .container {
    flex-direction: column;
  }
  .wrapper:after {
    max-width: 600px;
    transform: scale(0.8);
    top: 40%;
    margin: 0 auto;

  }
  .button{
    position: absolute;
    top: 170%;
    left: 18%;
  }
}
