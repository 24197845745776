.section {
  padding-bottom: 3rem;
  display: block;
  max-width: 1440px;
  margin: 0 auto;
  font-family: "Proxima Nova regular";
  }

.shabuWrap {
  padding: 70px 30px;
  margin: 0 auto;
}

.shabu1200 {
  max-width: 1280px;
}
.defaultTitle {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  line-height: 100%;
  color: #171717;
  margin-top: 10px;
  font-family: "Proxima Nova regular";
}

.whitetitle {
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  color: white;
  margin-top: 10px;
  position: absolute;
  top: 18%;
  left: 0;
  right: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  gap: 35px;
  padding: 0 50px;
  position: relative;
}

.wrapper::before {
  content: "";
  position: absolute;
  max-width: 304px;
  max-height: 186px;
  width: 100%;
  height: 100%;
  top: -6%;
  left: 11%;
  z-index: 0;
  background-image: url(./img/redcircle.png);
}

.wrapperOneCol {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  width: 100%;
  column-gap: 40px;
  row-gap: 35px;
  padding: 0 50px;
}

.container {
  width: 80%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col1 {
  display: block;
  width: auto;
  padding: 10px;
}

.col2 {
  display: block;
  width: auto;
  padding: 10px;
}

// solution card

.pwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 100%;
  column-gap: 40px;
  row-gap: 35px;
}

.pcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.card {
  background-color: rgb(255, 252, 252);
  display: grid;
  grid-template-columns: 50% 1fr;
  width: 100%;
  max-width: 538px;
  max-height: 450px;
  justify-self: center;
  justify-content: center;
  column-gap: 20px;
  border-radius: 10px;
  padding: 25px 10px 25px 0;
  z-index: 1;
}

.cardcontainer {
  width: 100%;
}
.pimage {
  display: block;
  position: absolute;
  height: 100%;
  max-height: 380px;
  max-width: 269px;
  // left: 20px;
  top: 0;
}
.pimageslim {
  display: block;
  position: absolute;
  height: 100%;
  max-height: 380px;
  max-width: 269px;
  left: 25%;
  top: 0;
}
.pimageslimextra {
  display: block;
  position: absolute;
  height: 100%;
  max-height: 380px;
  max-width: 269px;
  left: 40%;
  top: 0;
}
.ptextblock {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-size: 14px;
  padding-left: 20px;
}

.dotlist {
  max-width: 214px;
  padding-left: 10px;
}
.dotlist li {
  list-style-type: disc;
  font-size: 12px;
  line-height: 17px;
  font-family: "Roboto Regular";
}
.ptitle {
  font-size: 19px;
  text-align: left;
  font-weight: 900;
  padding-bottom: 10px;
  line-height: 25px;
  font-family: "Proxima Nova Black";
}

.pdescr {
  text-align: left;
  padding-bottom: 30px;
  font-family: "Roboto Regular";
  font-size: 13px;
  line-height: 17px;
}

.imgblock {
  position: relative;
  height: 100%;
  align-self: center;
  justify-self: center;
  width: 100%;
}

///green card
.cardExtra {
  display: flex;
  background: linear-gradient(
    125.37deg,
    #709a71 6.32%,
    rgba(61, 150, 63, 0.78) 101.47%
  );

  width: 100%;
  max-width: 500px;
  max-height: 417px;
  justify-self: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.cardExtra::before {
  content: "";
  position: absolute;
  max-width: 368px;
  transform: scale(0.8);
  max-height: 145px;
  width: 100%;
  height: 100%;
  top: -5%;
  z-index: 0;
  background-image: url(./img/whiteTop.png);
}
.cardExtra::after {
  content: "";
  position: absolute;
  max-width: 134px;
  max-height: 119px;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-image: url(./img/whiteBottom.png);
}
.pgreentextblock {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  z-index: 1;
}
.pgreentextblock::before {
  content: "";
  position: absolute;
  max-width: 38px;
  max-height: 38px;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: 0;
  z-index: 0;
  background-image: url(./img/whiteCircle.png);
}
.pgreentextblock span {
  font-size: 40px;
  font-family: "Proxima Nova Regular";
  font-weight: 900;
  max-height: 200px;
  color: #1c4326;
}
.greenTitle {
  font-size: 40px;
  font-family: "Proxima Nova Regular";
  font-weight: 900;
  padding: 35px 0;
  max-width: 355px;
  max-height: 110px;
  line-height: 0.9;
  color: white;
  text-align: center;
}
.greenText {
  font-size: 14px;
  font-family: "Roboto Thin";
  font-weight: 400;
  max-width: 339px;
  height: auto;
  color: white;
  padding: 20px 0;
  text-align: center;
  letter-spacing: -0.656526px;
  line-height: 17px;
}

//black block

.formBlock {
  .wrapper {
    max-width: 1920px;
    width: 100%;
    position: relative;
    height: 700px;
    margin: 0 auto;
    background-size: cover;

    @media (max-width: 500px) {
      height: unset;
      background-size: cover;
      margin-bottom: 60px;
    }

    .container {
      box-sizing: border-box;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 100px 0 0 35px;

      @media (max-width: 500px) {
        padding: 60px 20px 50px;
      }

      h4.title {
        &.white {
          span:first-of-type {
            text-transform: none;
            font-family: "Helvetica Neue Medium", sans-serif;
            font-size: 27px;
            position: relative;
            width: fit-content;

            &:after {
              background-color: white;
            }
          }

          & + p {
            color: #fff;
          }

          * {
            color: white;
          }
        }

        span {
          font-family: "Helvetica Neue Black", sans-serif;
          text-transform: uppercase;
          display: block;
          line-height: 1.6;
        }

        span:first-of-type {
          text-transform: none;
          font-family: "Helvetica Neue Medium", sans-serif;
          font-size: 27px;
          position: relative;
          width: fit-content;

          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 60px;
            height: 1px;
            background-color: #171717;
            top: 40%;
            right: -70px;
          }
        }

        span:nth-child(2) {
          line-height: 1.2;
          font-size: 28px;
        }

        span:last-of-type {
          width: fit-content;
          font-size: 55px;
          margin-bottom: 10px;
          line-height: 1.3;
        }

        @media (max-width: 500px) {
          span:first-of-type {
            font-size: 16px;
          }
          span:nth-child(2) {
            font-size: 8.8vw;
          }
          span:nth-child(3) {
            font-size: 10vw;
          }
          span:last-of-type {
            font-size: 7.2vw;
          }
        }
      }

      p {
        font-family: "Helvetica Neue Roman", sans-serif;
        font-size: 16px;
        max-width: 390px;
        margin-bottom: 30px;

        @media (max-width: 500px) {
          max-width: 100%;
        }
      }

      form {
        width: 360px;

        input {
          width: 100%;
          margin-bottom: 18px;
        }

        @media (max-width: 500px) {
          width: 100%;
          margin-top: 20px;

          input {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.circle {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: #67a668;
  position: absolute;
  left: 45%;
  top: 35%;
}

.circletext {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: white;
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
}

// .textBlockIneCol{

// }
@media screen and (max-width: 900px) {
  .wrapper {
    grid-template-columns: 1fr;
    padding: 0;
    row-gap: 60px;
  }
}
@media screen and (max-width: 610px) {
  .card {
    grid-template-columns: 46% 1fr;
    height: 100%;
    max-height: 490px;
  }
  .shabuWrap{
    padding: 30px 30px;

  }
  .pimage {
    height: 100%;
    max-width: 195px;
    margin: 0 auto;
  }
  .ptitle {
    font-size: 16px;
    line-height: 14px;
  }
  .pdescr {
    font-size: 11px;
    line-height: 14px;
  }
  .dotlist li {
    font-size: 11px;
    line-height: 14px;
  }
  .ptextblock {
    padding-left: 14px;
    width: auto;
  }
  .pimageslim {
    left: 15%;
    max-width: 140px;
  }
  .greenTitle {
    font-size: 35px;
    padding: 15px 0;
  }
  .greenTitle span {
    font-size: 35px;
  }
  .greenText {
    padding: 15px 0;
  }
  .pimageslimextra {
    left: 30%;
  }
}
@media screen and (max-width: 950px) {
  .wrapper::before {
    display: none;
  }
}
