.nextArrow, .prevArrow {
  position: absolute;
  display: block;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background: center rgba(255, 255, 255, 0) no-repeat;
  transition: all 300ms;
}


