
a, a:visited {
  color: #333333;
}

a:hover {
  text-decoration: underline;
}

.container {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 0px 40.94px 5.06px rgba(3, 7, 8, 0.08);
  background-color: transparent;
  max-width: 1440px;
  margin: 0 auto;
}
.wrapper.minified {
  background-color: black;
}



.content {
  color: #333333;
  font-size: 20px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 65px;
  transition: all 300ms;
}

.content.minified {
  height: 85px;
}

.logo {
  position: absolute;
  // transform: translateX(-50%);
}

.logo img {
  width: 165px;
}

.leftBlock {
  display: flex;
  width: fit-content;
  width: -webkit-fill-available;
  padding-left: 300px;
}

.rightBlock {
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: flex-end;
  }
}

.link {
  display: flex;
  align-items: flex-start;
  font-size: 17px;
  margin-right: 6%;
}

.link.number {
  margin-right: 0;
  text-align: right;
  font-size: 20px;
}

.link img {
  display: inline-block;
  height: 17px;
  margin-right: 5px;
}

.link div {
  white-space: nowrap;
  font-family: "Geometria Medium", sans-serif !important;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //width: 185px;
  margin-right: 35px;
}

.contacts div {
  font-size: 16px;
}

.contacts a {
  position: relative;
}


.menuIcon {
  position: absolute;
  font-size: 60px;
  left: 12px;
  transition: all 200ms;
  cursor: pointer;
}

.menuIcon.opened {
  z-index: 3000;
  transform: scale(1.3);
}

.menuIcon.opened span {
  background-color: #171717;
}

.menuIcon span {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #333333;
  margin-bottom: 5px;
  transition: all 200ms;
  transform-origin: left;
}

.menuIcon.opened span:nth-child(1) {
  transform: rotate(45deg) translateY(-2px);
}

.menuIcon.opened span:nth-child(2) {
  opacity: 0;
}

.menuIcon.opened span:nth-child(3) {
  transform: rotate(-45deg) translateY(2px);
}

@media screen and (max-width: 1200px) {
  .link, .contacts {
    display: none;
  }
  .searchIcon {
    display: none;
  }
  .rightBlock a[aria-current], .rightBlock a[href='/booking'] {
    display: none;
  }
  .content {
    height: 70px;
    transition: all 400ms;
  }

  .content.minified {
    height: 55px;
  }

  .logo img {
    width: 190px;
    transition: all 400ms;
  }

  .content.minified .logo img {
    width: 115px;
  }

  .container {
    padding: 0 15px 0 10px;
  }
}

.button {
  font-size: 16px;
  color: black;
  background-color: white;
  max-width: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  white-space: nowrap;
  font-weight: 600;
  font-family:'Proxima Nova regular';
  transition: all 300ms;
  cursor: pointer;

  &:hover {
    background-color: rgb(245, 245, 245);
  }

  @media (max-width: 1200px) {
    padding: 0;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    background-color: #188F36;
    width: 43px;
    height: 43px;
  }

}

@media screen and (max-width:600px) {
  .content{
    padding: 0 10px;

  }
  .logo img{
    width: 115px;
  }
  .leftBlock{
    display: none;
  }
  .rightBlock{
    width: 100%;
  }
  .wrapper{
    background-color:black;
  }

}
