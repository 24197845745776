.card{
    width: 100%;
    height: 100%;
    max-width: 330px;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px 10px;
    margin: 0 auto;


  }
  
  .icon{
    border-radius: 50%;
    background-color:  #67A668;
    height: 110px;
    width:110px;
    text-align: center;
    display: flex;
    align-content: center;

   }
  .icon img{
    max-width: 90px;
     height: auto;
    vertical-align: middle;
    margin: 0 auto;


  }
  
  .title {
    font-family: 'Roboto Thin';
    font-size: 20px;
    font-weight: 900;
    color:#ffffff;
    padding :15px 0;
 }
 .descr{
  font-family: 'Roboto Thin';
  font-size: 16px;
  font-weight: 500;
  color:#ffffff;
  line-height: 32px;
 
}