body {
  overflow: auto;
  overflow-x: hidden;
  
}

button {
  border: none;
  cursor: pointer
}

*:focus {
  outline: none;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding-left: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 16px;
  margin: 0;
}

b, strong {
  font-family: "Proxima Nova Black";
}

a, a:visited {
  text-decoration: none;
}

