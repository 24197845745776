.card{
  position: relative;
  height: 350px;
  width: 680px;
  }
.card img{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
   object-fit: cover;
  height: 100%;
 

}
.container{
    position: relative;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    // display: flex;
    // align-items: flex-end;
 }
 