.container {
  display: flex;
  max-width: 286px;
  max-height: 80px;
  align-items: center;
  margin: 0 auto;
}
.button {
  cursor: pointer;
  display: block;
  font-family: 'Roboto Regular';
  font-size: 18px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  transition: all 200ms;
  margin: 0 auto;
  z-index: 1;
  background: linear-gradient(107.01deg, #ffffff 3.46%, #b3d5b4 86.44%);
}
.greenButton {
  cursor: pointer;
  display: block;
  font-family: 'Roboto Thin';
  font-weight: 600;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  transition: all 200ms;
  margin: 0 auto;
  background: linear-gradient(104.68deg, #709A71 -8.55%, #178E35 87.61%);
}
.greenButton p {
  font-family: 'Roboto Thin';
  font-size: 18px;
  font-size: 18px;
  font-weight: 600;;
  line-height: 26px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
  height: 100%;
  color: #FFFFFF;
}

.button p {
  font-family: 'Roboto Regular';
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  max-width: 125px;
  margin: 0 auto;
  height: 100%;
  color: #090909;
}

@media screen and (max-width: 768px) {
  .button {
    box-sizing: border-box;
    width: 100%;
    font-size: 14px !important;
  }
  .button p {
    font-size: 18px;
  }
}
