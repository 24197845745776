.wrapper {
  background-color: black;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 1;
}
.wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 370px;
  height: 300px;
  background-image: url('../../pages/CoffeeSite/img/ballleft.png');
  z-index: 2
}

.wrapper::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;

  width: 165px;
  height: 173px;

  background-image: url('../../pages/CoffeeSite/img/ballright.png');
}

.container {
  max-width: 1120px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.title p {
  text-align: center;
  font-family: 'Roboto Regular';
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  padding: 70px 0;
}

.footer {
  display: grid;
}

.row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width:1050px) {
  .row{
    justify-content: space-around;
  }
}
.col1{
display: block;
width:auto;
max-width: 300px;
padding: 7px;
color: #ffffff;
padding-bottom: 50px;


}
.col2{
  display: block;
  width: auto;
  padding: 7px;
  max-width: 310px;
  color: #ffffff;

}
.col3{
  display: block;
  width: 100%;
  max-width: 449px;
  padding-bottom: 50px;
  padding: 7px;
  background-color: rgba(196, 196, 196, 0.23);
  border-radius: 10px;
  max-height: 300px;

}
.filter{
  filter: grayscale(100%);
}
@media screen and (max-width: 480px) {
  .title p{
    line-height: 31px;
    padding: 40px 0;
    }
}