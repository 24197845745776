@font-face {
    font-family: 'Proxima Nova Light';
    src: url('fonts/proximanova_light.otf');
}
@font-face {
    font-family: 'Proxima Nova Black';
    src: url('fonts/proximanova_black.otf');
}
@font-face {
    font-family: 'Proxima Nova  Bold';
    src: url('fonts/proximanova_bold.otf');
}
@font-face {
    font-family: 'Proxima Nova regular';
    src: url('fonts/proximanova_regular.ttf');
}
@font-face {
    font-family: 'Proxima Nova Ex Cond Normal';
    src: url('fonts/ProximaNovaExCn-Regular.ttf');
}
@font-face {
    font-family: 'Proxima Nova Ex Cond Black';
    src: url('fonts/ProximaNovaExCn-Black.ttf');
}
@font-face {
    font-family: 'Roboto Thin';
    src: url('fonts/Roboto-Thin.ttf');
}
@font-face {
    font-family: 'Roboto Regular';
    src: url('fonts/Roboto-Regular.ttf');
}
@font-face {
    font-family: 'Proxima Nova Extra Bold';
    src: url('fonts/proximanova_extrabold.otf');
}
@font-face {
    font-family: 'Proxima Nova Thin';
    src: url('fonts/ProximaNovaT-Thin.ttf');
}





