.section {
  padding-bottom: 3rem;
  display: block;
  max-width: 1440px;
  margin: 0 auto;
  font-family: "Proxima Nova regular";
}
.wrapper {
  background-color: black;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 1;
}
.wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 370px;
  height: 300px;
  background-image: url(../img/ballleft.png);
  z-index: 2;
}

.wrapper::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
   width: 165px;
  height: 173px;
   background-image: url(../img/ballright.png);
}
.container {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  padding: 20px 0 30px 0;

}
  .title p {
    text-align: center;
    font-family: 'Roboto Regular';
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    padding: 70px 0;
  }
  @media screen and (max-width: 480px) {
    .title p{
      line-height: 31px;
      font-size: 30px;
      padding: 40px 0;
      }
      .container{
        padding: 0; 
      }
  }
  .sliderContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media screen and (max-width:1050px) {
    .sliderContainer{
      justify-content: space-around;
    }
  }



  .card{
    width: 100%;
    height: 100%;
    max-width: 330px;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px 0;
        margin: 0 auto;


  }
  
  .icon{
    border-radius: 50%;
    background-color:  #67A668;
    height: 110px;
    width:110px;
    text-align: center;
    display: flex;
    align-content: center;

   }
  .icon img{
    max-width: 90px;
     height: auto;
     margin: 0 auto;

  }
  
  .title {
    font-family: 'Roboto Thin';
    font-size: 20px;
    font-weight: 900;
    color:#ffffff;
    padding :15px 0;
 }
 .descr{
  font-family: 'Roboto Thin';
  font-size: 16px;
  font-weight: 500;
  color:#ffffff;
  line-height: 32px;
 }
  

